import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import TrafficChangeVisualizer from './Components/TrafficChangeVisualizer';
import DisplayRankings from './Components/DisplayRankings';
import DDosAttackVisualizer from './Components/DDosAttackVisualizer';


function App() {

  return (
    <BrowserRouter>
    <div className='app'>
      <Routes>
        <Route path="/" element={<TrafficChangeVisualizer/>}/>
        <Route path="/visualizetraffic" element={<TrafficChangeVisualizer/>}/>
        <Route path="/displayranking" element={<DisplayRankings/>}/>
        <Route path="/visualizeddosattacks" element={<DDosAttackVisualizer/>}/>
      </Routes>
    </div>
    </BrowserRouter>
  );
}

export default App;
