import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import Headerbar from '../Header';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  
export default function DisplayRankings() {

    const [rankings, setRankings] = React.useState([]);

    React.useEffect(()=>{
        axios.get("https://general-assignment.kshitizsareen709.workers.dev/popular-domains").then(res=>{
            setRankings(res.data.rankingEntries);
        })
    },[])

    return (
        <div className="App">
            <Headerbar/>
            <h2>Display Rankings for Popular Domains</h2>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Rank</StyledTableCell>
              <StyledTableCell align="right">Rank Change</StyledTableCell>
              <StyledTableCell align="right">Domain</StyledTableCell>
              <StyledTableCell align="right">Category</StyledTableCell>
              <StyledTableCell align="right">Domain Status</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rankings.map((row,index) => (
              <StyledTableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0} }}
                style={
                    {
                    backgroundColor: row["Rank Change"] === -1 ? "#B22222" : row["Rank Change"] === 1 ? "#7CFC00" : "" 
                    }
                }
              >
                <TableCell component="th" scope="row">
                  {row.Rank}
                </TableCell>
                <StyledTableCell align="right">{row["Rank Change"]}</StyledTableCell>
                <StyledTableCell align="right">{row["Domain"]}</StyledTableCell>
                <StyledTableCell align="right">{row["Category"]}</StyledTableCell>
                <StyledTableCell align="right">{row["Rank Change"] === -1 ? "Ranking Dropped by 1 position" : row["Rank Change"] === 1 ? "Ranking Picked up by 1 position" : "No Change" }</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </div>
    );
  }