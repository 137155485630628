import '../App.css';
import React,{useEffect, useState} from 'react';
import axios from 'axios';
import * as d3 from 'd3';
import DatePicker from 'react-date-picker';
import Headerbar from '../Header';

function DDosAttackVisualizer() {

  const [ddosData,setddosData] = useState([]);
  const lineChartRef = React.useRef(null);
  const margin = {top: 10, right: 30, bottom: 50, left: 60};
  const width = window.parent.outerWidth/1.25;
  const height = window.parent.outerHeight/2.5;
  const svgWidth = width + margin.left + margin.right;
  const svgHeight =  height + margin.top + margin.bottom;
  var lineXScale = d3.scaleTime().range([0,width]);
  var lineYScale = d3.scaleLinear().range([height,0]);
  const formatDate = d3.timeFormat("%b %d");
  const [startDate,setStartDate] = useState(new Date("2022-07-24"));
  const [endDate,setEndDate] = useState(new Date("2022-08-25"));
  const [value,setValue] = useState("");
  const [date,setDate] = useState("");

  
  var lineXAxis = d3.axisBottom(lineXScale);
  lineXAxis.tickFormat(formatDate).ticks(d3.timeDay, formatDate);
  var lineYAxis = d3.axisLeft(lineYScale);
 


  var circleRadius = 3;

  useEffect(()=>{
    var lineSvgRef = d3.select(lineChartRef.current);
    lineSvgRef.selectAll('*').remove();

    var lineSvg = lineSvgRef.append("g").attr("transform",`translate(${margin.left},${margin.top})`);

    lineSvg.append("g").attr("class","xAxis").attr("transform","translate(0,"+height+")");
    lineSvg.append("g").attr("class","yAxis");

    lineSvg.append('g').attr('class','lines');

    lineSvg.append("text")
    .attr("transform", "translate(" + (svgWidth/2) + " ," + (svgHeight-20) + ")")
    .style("text-anchor", "middle")
    .text("Date");
 
    lineSvg.append("text")
    .attr("transform", "rotate(-90)")
    .attr("x", -(svgHeight/2))
    .attr("y",-40)
    .style("text-anchor", "middle")
    .text("Percentage of DDos Attack");

  },[height, margin.left, margin.top, svgHeight, svgWidth])

  useEffect(()=>{


    axios.get('https://general-assignment.kshitizsareen709.workers.dev/attack-layer3').then(res=>{
      let ddosData = res.data.data;
      let formattedDDosData = [];
      let startTimestamp = startDate.getTime();
      let endTimestamp = endDate.getTime();
      for(let i=0;i<ddosData.length;i++)
      {
        let currentTimeStamp=new Date(ddosData[i]['timestamp']).getTime()
        if (currentTimeStamp>=startTimestamp && currentTimeStamp<=endTimestamp)
        {
        formattedDDosData.push({
          'type': 'total',
          'timestamp': new Date(ddosData[i]['timestamp']),
          'value': ddosData[i]['value']*100
        })
        }
      }
      setddosData(formattedDDosData);
    })


  },[endDate,startDate])

  useEffect(()=>{

    setLineData()

  },[setLineData, ddosData]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function setLineData()
  {
    var t = d3.transition()
    .duration(750)

    var svgLine = d3.select(lineChartRef.current);
    var lineSvg = svgLine.select("g");
  
    let minX = Infinity;
    let maxX = 0;
  
    let minY = Infinity;
    let maxY = 0; 
  
    let minDate = "";
    let maxDate = "";

    for(let i=0;i<ddosData.length;i++)
    {
      let convertedTimestamp = new Date(ddosData[i].timestamp).getTime();
      if(convertedTimestamp>maxX)
      {
        maxX=convertedTimestamp;
        maxDate = ddosData[i].timestamp;
      }
      if(convertedTimestamp<minX)
      {
        minX=convertedTimestamp;
        minDate = ddosData[i].timestamp;
      }
      if(ddosData[i].value>maxY)
      {
        maxY=ddosData[i].value;
      }
      if(ddosData[i].value<minY)
      {
        minY=ddosData[i].value;
      }
    }
    lineXScale.domain([minDate,maxDate]);
    lineYScale.domain([minY,maxY]);

    lineSvg.selectAll('.xAxis').transition(t).call(lineXAxis);
    lineSvg.selectAll('.yAxis').transition(t).call(lineYAxis);

    var sumstat = d3.group(ddosData,d=>d.type);

    var lines=lineSvg.selectAll(".line")
    .data(sumstat)
    const color = d3.scaleOrdinal()
      .range(['#e41a1c'])
    
    lines.enter().append("path")
    .attr("class","line")
    .merge(lines)
    .transition(d3.transition()
    .duration(1500))
    .attr("fill","none")
    .attr("stroke",function(d){ return color(d[0])})
    .attr("stroke-width",1.5)
    .attr("d",function(d)
    {
      return d3.line().x(function(d){
        return lineXScale(d.timestamp);
      })
      .y(function(d){
        return lineYScale(d.value);
      })
      (d[1])
    })

    var circle=lineSvg.selectAll(".circle")
    .data(ddosData)

    circle.enter().append("circle").on('mouseover',(e,d)=>{
      let today = d.timestamp;
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();
  
  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;

  setValue(d.value.toFixed(3) + ' %');
  setDate(d.timestamp.toLocaleString('en-US', {
    timeZone: 'America/Los_Angeles',
    dateStyle: 'full',
    timeStyle: 'full',
  }));
  
    })
    .on('mouseout',()=>{
      setValue("");
      setDate("");
    })
    .attr("class","circle")
    .merge(circle)
    .transition(t)
    .attr("fill","#614023")
    .attr("cx", d => lineXScale(d.timestamp))
    .attr("cy", d => lineYScale(d.value))
    .attr("r", circleRadius)
  
    circle.exit().remove();
    lines.exit().remove();

// Loop through each symbol / key

  }

  return (
    <div className="App">
        <Headerbar/>
      <div>
        <h2>Multi-Series Line Chart For DDos Attacks</h2>
        <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: '2%',
        marginBottom: '5%'
      }}>
        <div>
          <p>Start Date</p>
      <DatePicker onChange={setStartDate} value={startDate} minDate={new Date("2022-07-24")} maxDate={new Date("2022-08-25")}/>
      </div>
      <div>
        <p>End Date</p>
      <DatePicker onChange={setEndDate} value={endDate} minDate={new Date("2022-07-24")} maxDate={new Date("2022-08-25")}/>
      </div>
      </div>
      <div class="linecharttooltipvalue">Value: {value}</div>
        <div class="linecharttooltipdate">Date: {date.toString()}</div>
        <svg ref={lineChartRef} width={svgWidth} height={svgHeight}/>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            width: '20%',
            height: '10%',
          }}>
            <div style={{
              width: 200,
              height: 2,
              backgroundColor: '#e41a1c',
              alignSelf: 'center',
              marginBottom: '5%'
            }}/>
            <p>Total</p>
            </div>
          </div>
      </div>
    </div>
  );
}

export default DDosAttackVisualizer;
